.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-header {
  margin-bottom: 2rem;
  width:100%;
  text-align: center;
}

.App-chat {
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  width:100%;
}

.App-chat h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.AssistantChat {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
